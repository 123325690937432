import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  Drawer,
  MenuItem,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import EvoFuture from '../assets/EvoFuture.ico'; // Adjust path as needed
import ColorModeIconDropdown from '../ColorModeIconDropdown';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.divider,
  backgroundColor: theme.vars
    ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
    : alpha(theme.palette.background.default, 0.4),
  boxShadow: (theme.vars || theme).shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (section) => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById(section)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 100);
  };

  const toggleDrawer = (newOpen) => () => setOpen(newOpen);

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              textDecoration: 'none', // Prevents link underline
            }}
          >
            <Box
              component="img"
              src={EvoFuture}
              alt="Logo"
              sx={{ width: 35, height: 35, borderRadius: '50%' }}
            />
            <Typography variant="h6" color="primary">
              EvoFuture
            </Typography>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {[
              { label: 'Service', id: 'services' },
              { label: 'Projects', id: 'projects' },
              { label: 'Testimonials', id: 'testimonials' },
              { label: 'Contact Us', id: 'contact-us' },
            ].map((item) => (
              <Button
                key={item.id}
                onClick={() => handleNavClick(item.id)}
                variant="text"
                color="info"
                size="small"
              >
                {item.label}
              </Button>
            ))}
            <Button
              component={RouterLink}
              to="/blog"
              variant="text"
              color="info"
              size="small"
            >
              Blog
            </Button>
            {/* <Button
              component={RouterLink}
              to="/news"
              variant="text"
              color="info"
              size="small"
            >
              News
            </Button> */}
            <ColorModeIconDropdown />
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', gap: 1 }}>
            <ColorModeIconDropdown />
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{ sx: { top: 'var(--template-frame-height, 0px)' } }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                {[
                  { label: 'Service', id: 'services' },
                  { label: 'Projects', id: 'projects' },
                  { label: 'Testimonials', id: 'testimonials' },
                  { label: 'Contact Us', id: 'contact-us' },
                ].map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      handleNavClick(item.id);
                      toggleDrawer(false)();
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  component={RouterLink}
                  to="/blog"
                  onClick={toggleDrawer(false)}
                  color="info"
                  size="small"
                >
                  Blog
                </MenuItem>
                <Divider sx={{ my: 3 }} />
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
