import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// Styled Dialog
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#F4F4F4',
    color: '#000',
    borderRadius: '16px',
    padding: '3px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    width: '90%',
    maxWidth: '500px',
    height: 'auto',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
  },
}));

// Styled Close Button
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '8px',
  right: '8px',
  backgroundColor: '#000',
  color: '#fff',
  borderRadius: '50%',
  padding: '8px',
  '&:hover': {
    backgroundColor: '#333',
  },
}));

// Ad Component
export default function Ad() {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const dialogShown = sessionStorage.getItem('offersDialogShown');
    if (!dialogShown) {
      const timer = setTimeout(() => {
        setOpenDialog(true);
        sessionStorage.setItem('offersDialogShown', 'true');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <StyledDialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="ad-dialog-title">
      {/* Close Button */}
      <CloseButton aria-label="close" onClick={handleCloseDialog}>
        <CloseIcon />
      </CloseButton>

      {/* Dialog Content */}
      <img
        src={require('../assets/ad001.png')} // Replace this with your actual image path
        alt="Exclusive Offer"
        style={{ width: '100%', borderRadius: '16px' }}
      />
    </StyledDialog>
  );
}
