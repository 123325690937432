import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import background from '../assets/mainbig.png';
import backgroundSmall from '../assets/mainsmall.png'; // Add small screen background image

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TikTokIcon from '@mui/icons-material/MusicNote'; // No specific icon for TikTok in MUI, using MusicNote as placeholder
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { SiLinktree } from "react-icons/si";
import EmailIcon from '@mui/icons-material/Email';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '90%',
  height: 450,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${background})`, // Default large screen image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 550,
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${backgroundSmall})`, // Small screen background image
    height: 300,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            Welcome&nbsp;to&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              EvoFuture
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Empowering businesses with innovative software and network solutions. Discover how EvoFuture is shaping the future of technology.
          </Typography>
          {/* Social Media Icons */}
          <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
            <IconButton component="a" href="https://www.facebook.com/evofuture" target="_blank" color="primary">
              <FacebookIcon />
            </IconButton>
            <IconButton component="a" href="https://instagram.com/official_evofuture" target="_blank" color="primary">
              <InstagramIcon />
            </IconButton>
            <IconButton component="a" href="https://www.linkedin.com/company/evo-future/" target="_blank" color="primary">
              <LinkedInIcon />
            </IconButton>
            <IconButton component="a" href="mailto:team.evofuture@gmail.com" color="primary">
              <EmailIcon />
            </IconButton>
            <IconButton component="a" href="https://wa.me/94769374123" target="_blank" color="primary">
              <WhatsAppIcon />
            </IconButton>
            <IconButton component="a" href="https://linktr.ee/EvoFuture" target="_blank" color="primary">
              <SiLinktree />
            </IconButton>
          </Stack>
        </Stack>
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}
