import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Services from './components/Services';
import ContactUs from './components/ContactUs';
import Projects from './components/Projects';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import AppTheme from './AppTheme';
// import Snowfall from 'react-snowfall'; // Snowfall effect for Christmas
import Confetti from 'react-confetti'; // Confetti effect for New Year
import Box from '@mui/material/Box';
import ProjectPage from './components/ProjectPage';
import MainDrawer from './components/MainDrawer';
import BlogPage from './components/BlogPage';
import LoginPage from './components/LoginPage';
import BusinessAgreementPage from './components/BusinessAgreementPage';
import PrivateRoute from './components/PrivateRoute';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton';
import ScrollToTopButton from './components/ScrollToTopButton';
// import OffersDialog from './components/OffersDialog';
import Ad from './components/Ad';
import OfferAd from './components/OfferAd';


export default function App(props) {
  const [showConfetti, setShowConfetti] = React.useState(true);

  // Stop confetti after 10 seconds
  React.useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 35000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <AppTheme {...props}>
        <CssBaseline enableColorScheme />

        {/* New Year Confetti */}
        {showConfetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={300}
            gravity={0.1}
          />
        )}

        {/* Commented out Snowfall effect for Christmas */}
        {/*
        <Snowfall
          color="white"
          snowflakeCount={50}
          speed={[1, 2]}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1,
            pointerEvents: 'none',
          }}
        />
        */}

        {/* Include OffersDialog Component here */}
        {/* <OffersDialog /> */}

        {/* Floating WhatsApp Button */}
        <FloatingWhatsAppButton />

        {/* Scroll to Top Button */}
        <ScrollToTopButton />

        <Ad />
        <OfferAd />

        <Routes>
          {/* Marketing Page */}
          <Route
            path="/"
            element={
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 2,
                  width: '100%',
                  overflowX: 'hidden',
                }}
              >
                <AppAppBar />
                <Hero />
                <div>
                  <div id="services">
                    <Services />
                  </div>
                  <LogoCollection />
                  <div id="projects">
                    <Projects />
                  </div>
                  <div id="testimonials">
                    <Testimonials />
                  </div>
                  <br />
                  <br />
                  <Divider />
                  <div id="contact-us">
                    <ContactUs />
                  </div>
                  <Footer />
                </div>
              </Box>
            }
          />

          {/* Business Agreement Page */}
          <Route path="/Terms&Conditions" element={<BusinessAgreementPage />} />

          {/* Project Page */}
          <Route path="/projects" element={<ProjectPage />} />

          {/* Blog Page */}
          <Route path="/blog" element={<BlogPage />} />

          {/* Login Page */}
          <Route path="/login" element={<LoginPage />} />

          {/* Dashboard - Protected Route */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <MainDrawer />
              </PrivateRoute>
            }
          />
        </Routes>
      </AppTheme>
    </Router>
  );
}
